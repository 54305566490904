@use "@els/els-styleguide-core/scss/core" as *;

.c-ssa-ai-chat-admin-traces-modal {
  pre {
    text-wrap: wrap;
    white-space: pre-wrap;
    padding: $els-space;
    background: $els-color-n0;
    border-radius: $els-space-1o2;
  }
}

.c-ssa-ai-chat-admin-override-modal {
  display: flex;
  flex-direction: column;

  &__top {
    top: 0;
    margin-bottom: $els-space-1o2;
  }

  &__main {
    flex: 1;
    overflow-y: auto;
  }

  &__bottom {
    margin-top: $els-space-1o2;
    bottom: 0;
  }

  &__title-column {
    min-width: 1px;
  }

  &__description {
    border-radius: $els-space-1o2;
    background-color: $els-color-n3;
    padding: $els-space-1o2;
    margin: $els-space-1o2 0;
  }

  &__prompt {
    margin: $els-space-2x 0;
    padding: $els-space;
    border-radius: $els-space-1o2;
    background-color: $els-color-n0;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__field {
    margin: $els-space-2x 0;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  textarea {
    resize: vertical;
  }
}
