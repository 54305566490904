@use "@els/els-styleguide-core/scss/core" as *;

$c-ssa-student-study-page__brain-image: 'ELS_ALL_Editorial Illustration_Sharing_Banner.jpg';

.c-ssa-student-study-page{
  &__instruction{
    margin-bottom: $els-space-2x;
  }

  &__total-quizzes-card{
    background-color: rgba(255,255,255,0.95);
    padding: $els-space-1x $els-space-2x;
  }

  &__create-new-quiz-button{
    margin-top: $els-space;
  }

  &__title{
    padding: $els-space;
  }

  &__banner-container{
    background-image: url($c-ssa-student-study-page__brain-image);
    background-size: cover;
    padding: $els-space-4x $els-space-2x;
  }

  &__prompt{
    margin-top: $els-space;
    margin-bottom: $els-space-2x;
  }

  &__body-container{
    margin:auto;
    margin-top: $els-space;
    padding: $els-space $els-space-2x;
  }

  &__footer-container{
    padding: $els-space-none $els-space-2x;
    margin: auto;
  }
}
