@use "@els/els-styleguide-core/scss/core" as *;

.c-ssa-eaq-student-performance-details {
  &__description-block {
    background-color: $els-color-n0;
    padding: $els-space $els-space-2x;
  }
  &__gauge-chart {
    text-align: center;
    background-color: white;
    padding: $els-space $els-space-2x;
  }
  &__page-content {
    padding: $els-space-2x
  }
}
