@use "@els/els-styleguide-core/scss/core" as *;

.c-ssa-ai-chat-performance {
  padding: $els-space-2x;
  overflow: scroll;

  @include mq($until: mobile) {
    padding: $els-space-1x;
  }

  &__width {
    max-width: 50 * $els-space;
    margin: 0 auto;
  }

  &__chart {
    width: 100%;

    canvas {
      width: 100% !important;
      height: auto !important;
    }

    &-cir {
      width: $els-space;
      height: $els-space;
      border-radius: $els-space;
      border-color: $els-color-primary;
      border-width: $els-space-1o8;
      border-style: solid;
    }

    &-mtn {
      position: relative;
      width: $els-space;
      height: $els-space;
      border-radius: $els-space;
      background: $els-color-n7;

      &::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        height: $els-space-1o2;
        background: $els-color-n7;
        width: 100%;
      }
    }

  }

}
