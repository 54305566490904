@use "@els/els-styleguide-core/scss/core" as *;

.c-els-user-feedback {

  max-width: 35 * $els-space;

  &__button-wrap {
    margin-bottom: $els-space;
  }

  &__button-input {
    position: absolute;
    opacity: 0;
    &:focus + .c-els-user-feedback__button {
      @include focus-default();
    }
  }

  &__button {
    border: $els-thin-keyline-width $els-color-secondary solid;
    border-radius: $els-space-1o2;
    padding: $els-space-1o2 $els-space-1x;
    background: white;
    display: inline-block;

    &:hover {
      cursor: pointer;
    }

    &--active {
      background-color: $els-color-secondary;
      color: white;
    }

    &--disabled {
      background-color: $els-color-n3;
      color: white;

      &:hover {
        cursor: not-allowed;
      }
    }
  }

  &__controls {
    margin-top: $els-space-1x1o2;
  }
}
