@use "@els/els-styleguide-core/scss/core" as *;

.c-els-authoring {

  background-color: $els-color-n1;
  min-height: 100vh;

  .c-els-card {
    background-color: white;
  }

  &__pre {
    text-wrap: wrap;
    white-space: pre-wrap;
    font-family: $els-font-base;
  }
}
