@use "@els/els-styleguide-core/scss/core" as *;
@use "../../assets/settings" as *;

.c-scm-ebook-player {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: column;
  background: $els-color-n1;

  .c-els-page-header {
    border-bottom-color: $els-color-n3
  }

  &__header {
    flex: 0 1 auto;
  }

  &__title {
    display: block;
    width: 100%;
    max-width: $cw-max-page-width;
    margin: auto;
  }

  &__body {
    min-height: 0;
    background: $els-color-n1;
  }

  &__page {
    padding: $els-space-2x;
    overflow: scroll;
  }

}
