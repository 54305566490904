@use "@els/els-styleguide-core/scss/core" as *;

.c-ssa-self-study-assessment-editor-page {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: column;


  &__header {
    flex: 0 1 auto;
  }

  &__body {
    flex: 1 1 auto;
    min-height: 0;
    overflow: auto;
    &-content {
      padding: $els-space-2x;
    }
  }

  &__footer {
    border-top: $els-color-n3 solid $els-thick-keyline-width;
    flex: 0 1 auto;
    &-content {
      padding: $els-space $els-space-2x;
    }
  }

}
