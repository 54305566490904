@use "@els/els-styleguide-core/scss/core" as *;

.c-ssa-eaq-student-topic-report {

  &__banner {
    padding: $els-space-2x;
    background: linear-gradient(
        to bottom,
        #ffffff 0%,
        $els-color-extended-blue-0 100%
    );

    &-img {
      width: 30vw;
      max-width: $els-space * 20;
      max-height: 33vh;
    }

    &-txt {
      font-size: $els-fs-h1;
    }
  }
  &__page-title {
    margin-bottom: $els-space;
  }
  &__footer-container{
    padding: $els-space-none $els-space-2x;
    margin: auto;
  }
}
