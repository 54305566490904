@use "@els/els-styleguide-core/scss/core" as *;

$els-chat-border: $els-thick-keyline-width $els-color-n0 solid;


.c-ssa-ai-chat-base {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
  position: fixed;
  width: 100%;

  &__header {
    top: 0;
    padding: $els-space $els-space-1x1o2;
    border-bottom: $els-thick-keyline-width solid $els-color-n0;
  }

  &__logo-img {
    height: $els-space * 2.5;
  }

  &__page {
    display: flex;
    flex: 1;
    overflow: hidden;
  }

  &__sidebar {
    padding: $els-space-2x;
    border-right: $els-chat-border;
    max-width: 20 * $els-space;
    min-width: 10 * $els-space;
    width: 20%;
  }

  &__main {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-height: 1px;
  }

  &__rightbar {
    border-right: $els-chat-border;
    overflow-y: auto;
    width: 0;

    &--active {
      border: $els-thick-keyline-width solid $els-color-primary;
    }

    &-inner {
      padding: $els-space-2x;
    }
  }

  &__top {
    top: 0;
    padding: $els-space-1x $els-space-2x;

    @include mq($until: mobile) {
      padding: $els-space-1x;
    }
  }

  &__footer {
    bottom: 0;
    //padding: $els-space-1x $els-space-1x 0 $els-space-1x;
    &-slim {
      margin-top: $els-space;
      border-top: $els-thick-keyline-width solid $els-color-n1;
      padding: $els-space $els-space-2x;
    }


    &-logo {
      height: $els-space;
    }
  }

}
