@use "@els/els-styleguide-core/scss/core" as *;
@use "../../assets/remediation-color-settings" as *;

.c-ssa-remediation-activity-section {

  background: white;
  padding: $els-space;
  border: $els-thin-keyline-width solid $els-color-n1;
  position: relative;
  height: 100%;
  border-radius: $els-space-1o4;

  &--review_by_taxon {
    border-color: $remediation-green;

    .c-ssa-remediation-activity-section__checklist-item-icon {
      color: $remediation-green;
    }
  }

  &--apply_by_content_type {
    border-color: $remediation-pink;

    .c-ssa-remediation-activity-section__checklist-item-icon {
      color: $remediation-pink;
    }
  }

  &--assess_by_content_type {
    border-color: $remediation-purple;
  }

  &__checklist {

    &-header {
      margin-bottom: $els-space;
    }

    &-item-icon {
      display: block;
    }

    &-item {
      border: $els-thin-keyline-width solid $els-color-n2;
      border-radius: $els-space-1o4;
      margin: $els-space 0;
      padding: $els-space-1o2 $els-space $els-space-1o2 $els-space-1o2;
      display: block;
      width: 100%;

      &:hover {
        border-color: $els-color-n5;
        background-color: $els-color-n0;
      }

      &--center {
        text-align: center;
        padding: $els-space-1o2 $els-space;
      }

      &--completed {
        .c-ssa-remediation-activity-section__checklist-item-text {
          text-decoration: line-through;
          text-decoration-color: rgba(0, 0, 0, .4);
        }
      }

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

}
