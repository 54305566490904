@use "@els/els-styleguide-core/scss/core" as *;
@use "../../assets/remediation-color-settings" as *;

.c-ssa-remediation-post-player {
  background: $els-color-n0;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: column;

  &__header {
    flex: 0 1 auto;
  }

  &__body {
    flex: 1 1 auto;
    min-height: 0;
    overflow: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  &__complete_window {

  }

  &__window {
    background: white;
    padding: $els-space-3x;
    border-radius: $els-space-1o2;
    max-width: 50 * $els-space;
    margin: $els-space-2x;
  }

  &__progress-bar {
    max-width: 30 * $els-space;
    margin: 0 auto;
  }
}
