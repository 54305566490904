@use "@els/els-styleguide-core/scss/core" as *;

.c-ssa-remediation-home {
  background: $els-color-n0;
  min-height: 100vh;

  &__center {
    max-width: 60 * $els-space;
    margin: 0 auto;
  }

  &__header {
    background: white;
    padding: $els-space $els-space-2x;
  }

  &__header-info {
    min-width: 15 * $els-space;
  }

  &__main {
    padding: $els-space-2x;
  }

  &__main-header {
    margin-bottom: $els-space-2x;
  }

  &__progress {
    background-color: white;
    padding: $els-space-1o2 $els-space;
    border-radius: $els-space-1o2;
    border: $els-thin-keyline-width solid $els-color-n1;
    white-space: nowrap;

    .c-els-progress-bar {
      background: $els-color-n1;
    }

    .c-els-progress-bar__progress {
      background: $els-color-n9;
    }
  }

  &__tab {
    margin: $els-space-1x 0;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }


}
