@use "@els/els-styleguide-core/scss/core" as *;

.c-els-ai-chat-admin {

  pre {
    text-wrap: wrap;
    white-space: pre-wrap;
    font-family: $els-font-base;
  }

  &__entry-header {
    border-bottom: $els-space-1o4 solid $els-color-n0;
    padding-bottom: $els-space-2x;
    font-size: $els-fs-meta;
  }

  &__entry {
    margin: $els-space-2x 0;
    padding: $els-space-2x;
    border: $els-space-1o2 solid;
    border-radius: $els-space-1o2;


    &--user {
      border-color: $els-color-extended-blue-0;

      &.c-els-ai-chat-admin__entry--active {
        border-color: $els-color-extended-blue-6;
      }
    }

    &--bot {
      border-color: $els-color-extended-orange-0;

      &.c-els-ai-chat-admin__entry--active {
        border-color: $els-color-extended-orange-6;
      }
    }
  }

  &__entry-table {
    td:first-child {
      padding-right: $els-space;
    }
  }

  &__entry-body {
    margin: $els-space-2x 0 0 0;
  }

  &__entry-videos {
    border-top: $els-space-1o4 solid $els-color-n0;
    padding: $els-space-2x 0 0 0;
    margin: $els-space-2x 0 0 0;
  }

  &__entry-citations {
    border-top: $els-space-1o4 solid $els-color-n0;
    padding: $els-space-2x 0 0 0;
    margin: $els-space-2x 0 0 0;
  }
}
