@use "@els/els-styleguide-core/scss/core" as *;

.u-ssa-min-width-2x {
  min-width: $els-space-1x * 10;
}

.c-ssa-mastery-chart {
  &__bar {
    position: relative;
    height: $els-space-2x;
    width: 100%;

    &-indicator {
      width: $els-space-1o4;
      height: 100%;
      position: absolute;
      left: 0;
      background-color: $els-color-n5;

      &::before {
        content: '';
        position: absolute;
        top: $els-space-1o2;
        left: 50%;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: $els-space-1o2;
        border-color: transparent transparent $els-color-n5 transparent;
        transform: translate(-50%, -50%) rotate(180deg);
      }
    }

    &-section {
      height: 100%;
      width: 25%;
      position: absolute;

      &:nth-child(1) {
        left: 0;
        background-color: $els-color-n1;
      }

      &:nth-child(2) {
        left: 25%;
        background-color: $els-color-extended-purple-1;
      }

      &:nth-child(3) {
        left: 50%;
        background-color: $els-color-extended-purple-5;
      }

      &:nth-child(4) {
        left: 75%;
        background-color: $els-color-extended-purple-9;
      }
    }
  }
}
