@use "@els/els-styleguide-core/scss/core" as *;
@use "../../assets/remediation-color-settings" as *;

.c-ssa-recommendation-card {
  border-radius: $els-space-1o2;
  border: $els-thin-keyline-width solid $els-color-n3;

  &__main {
    padding: $els-space-1x;
  }

  &__banner {
    padding: $els-space-1x;
    text-align: center;
  }

  &--review_by_taxon {
    .c-ssa-recommendation-card__banner {
      background: rgba($remediation-green, 0.2);
    }
  }

  &--apply_by_content_type {
    .c-ssa-recommendation-card__banner {
      background: rgba($remediation-pink, 0.2);
    }
  }
}
