@use "@els/els-styleguide-core/scss/core" as *;
@use "../../assets/remediation-color-settings" as *;

.c-ssa-remediation-activity-progress {

  text-align: left;

  &--review_by_taxon {
    .c-els-progress-bar__progress {
      background: $remediation-green;
    }

    .c-els-progress-bar {
      background: rgba($remediation-green, 0.2);
    }
  }

  &--apply_by_content_type {
    .c-els-progress-bar__progress {
      background: $remediation-pink;
    }

    .c-els-progress-bar {
      background: rgba($remediation-pink, 0.2);
    }
  }

  &--assess_by_content_type {
    .c-els-progress-bar__progress {
      background: $remediation-purple;
    }

    .c-els-progress-bar {
      background: rgba($remediation-purple, 0.2);
    }
  }

}
