@use "@els/els-styleguide-core/scss/core" as *;

.c-ssa-remediation-quiz {
  padding: $els-space-2x;
  background: $els-color-n0;

  &__card {
    background: white;
  }
}
