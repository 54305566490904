@use "@els/els-styleguide-core/scss/core" as *;

.c-ssa-mastery-chart-key-table {

  &__row {
    margin-bottom: $els-space;
  }

  &__key {
    width: $els-space-2x;
    height: $els-space-2x;
    display: inline-block;

    &--level-1 {
      background-color: $els-color-extended-purple-1;
    }

    &--level-2 {
      background-color: $els-color-extended-purple-5;
    }

    &--level-3 {
      background-color: $els-color-extended-purple-9;
    }
  }
}
