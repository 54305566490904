@use "@els/els-styleguide-core/scss/core" as *;

.c-ssa-previous-chats-modal {

  display: flex;
  flex-direction: column;
  min-height: 1px;
  max-width: 40 * $els-space;
  width: 75%;

  &__top {
    top: 0;
    margin-bottom: $els-space-1o2;
  }

  &__main {
    flex: 1;
    overflow-y: auto;
  }

  &__bottom {
    margin-top: $els-space-1o2;
    bottom: 0;
  }

  &__title-column {
    min-width: 1px;
  }

  &__title-button {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }
}
